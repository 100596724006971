
import Vue from 'vue';
import utils from '../../../util';
import { DataTableHeader } from 'vuetify';
import { VForm } from 'vuetify/lib';
import { CallRailAccountConnection } from '../../../store/modules/callRail/types';

type UpdateAccount = {
  oldName: string;
  newName: string;
  accountId: string;
};

export default Vue.extend({
  name: 'CallRailDetailsNew',

  components: {
    linkedType: () => import('../_linkedTypeCR.vue'),
  },
  data: (): {
    dialog: {
      create: boolean;
      update: boolean;
    };
    isCreateFormValid: boolean;
    isUpdateFormValid: boolean;
    name: string | null;
    enabled: boolean;
    showDeleteConfirmation: boolean;
    validId: Array<Function>;
    validKey: Array<Function>;
    accounts: CallRailAccountConnection[];
    headers: DataTableHeader[];
    loading: {
      accounts: boolean;
      delete: boolean;
      create: boolean;
      update: boolean;
    };
    itemToDelete: CallRailAccountConnection | null;
    create: null | {
      accountId: string;
      name: string;
    };
    update: null | UpdateAccount;
    addConnectionForm: typeof VForm | null;
    updateConnectionForm: typeof VForm | null;
  } => ({
    dialog: {
      create: false,
      update: false,
    },
    isCreateFormValid: false,
    isUpdateFormValid: false,
    name: null,
    enabled: false,
    showDeleteConfirmation: false,
    validId: [
      v => !!v || 'ID is required',
      v => /^\S[a-zA-Z0-9]*$\S{0,}/g.test(v) || 'ID must be valid. No spaces or special characters.',
    ],
    validKey: [
      v => !!v || 'CallRail ID Key is required',
      v =>
        /^\S[a-zA-Z0-9-]*$\S{0,}/g.test(v) ||
        'CallRail ID must be valid (letters, numbers, dashes). No spaces or special characters.',
      v => (v && v.length > 3) || 'CallRail ID must be longer than 3 characters',
    ],
    headers: [
      {
        text: '',
        sortable: false,
        value: 'account',
      },
      {
        text: 'Ads',
        sortable: false,
        value: 'ads',
      },
      {
        text: '',
        sortable: false,
        value: 'actions',
      },
    ],
    accounts: [],
    loading: {
      accounts: false,
      delete: false,
      create: false,
      update: false,
    },
    itemToDelete: null,
    create: null,
    update: null,
    addConnectionForm: null,
    updateConnectionForm: null,
  }),

  created() {
    this.loadAccounts();
  },

  methods: {
    async loadAccounts() {
      try {
        this.loading.accounts = true;
        const accounts = await this.$store.dispatch('callRail/getCallRailAccounts', this.agencyId);
        if (accounts && !accounts.error) {
          this.accounts = accounts || [];
        } else {
          console.error(accounts);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading.accounts = false;
      }
    },
    async deleteAccount(account: CallRailAccountConnection) {
      try {
        this.loading.delete = true;
        const data = await this.$store.dispatch('callRail/deleteCallRailAccount', {
          accountName: account.name,
          agency: this.agencyId,
        });
        if (data) {
          this.itemToDelete = null;
          this.showDeleteConfirmation = false;
          this.loadAccounts();
        }
      } catch (error) {
        this.showDeleteConfirmation = true;
      } finally {
        this.loading.delete = false;
      }
    },
    async createAccount() {
      try {
        const isValidForm = this.$refs.addConnectionForm?.validate();
        if (!isValidForm) return;
        this.loading.create = true;
        const { accountId, name } = this.create;
        const data = await this.$store.dispatch('callRail/createCallRailAccount', {
          accountName: name,
          accountId,
          agency: this.agencyId,
        });
        if (data) {
          this.clear();
          this.loadAccounts();
        }
      } catch (error) {
      } finally {
        this.loading.create = false;
      }
    },
    async updateAccount() {
      try {
        const isValidForm = this.$refs.updateConnectionForm?.validate();
        if (!isValidForm) return;
        this.loading.update = true;
        const { accountId, oldName, newName } = this.update;
        const data = await this.$store.dispatch('callRail/updateCallRailAccount', {
          accountName: this.agencyId,
          agency: oldName,
          patch: {
            accountId,
            name: newName,
          },
        });
        if (data) {
          this.clear();
          this.loadAccounts();
        }
      } catch (error) {
      } finally {
        this.loading.update = false;
      }
    },
    addAccount() {
      this.create = { accountId: '', name: '' };
      this.dialog.create = true;
    },
    editAccount(item: CallRailAccountConnection) {
      this.dialog.update = true;
      this.update = {
        oldName: item.name,
        newName: item.name,
        accountId: item.id,
      };
    },
    showDeleteDialog(item: CallRailList): void {
      this.itemToDelete = item;
      this.showDeleteConfirmation = true;
    },
    clear() {
      this.dialog.create = false;
      this.dialog.update = false;
      this.create = null;
      this.update = null;
      this.$refs.addConnectionForm?.resetValidation();
      this.$refs.updateConnectionForm?.resetValidation();
    },
  },

  computed: {
    accountsIds(): Array<string> {
      return this.accounts.map(a => a.id);
    },
    agencyId(): string {
      return this.$route.params.agency;
    },
    isAgencyAdmin() {
      const roles = ['AGENCY_ADMIN'];
      return utils.hasRight(this.$store.state.customer.user, roles);
    },
  },
});
